import React from 'react';
import { render } from 'react-dom';
import  GenericSlideShowContainer  from '../genericSlideShow/genericSlideShowContainer';

const SingleImageCarousel = () => (
    <div>
      <GenericSlideShowContainer images={window.homeSlideShowImages} />
    </div>
  );
  

render(<SingleImageCarousel />, document.getElementById('slideShowComponent'));
