import React from 'react';
import { checkPropTypes } from 'prop-types';

function GenericSlideShowControls() {
  return (
    <div className="glide__arrows" data-glide-el="controls">
        <button id="slideBack" className="glide__arrow glide__arrow--left banner-btn-left" data-glide-dir="<"><span className="icon-carousel-arrow-left" /></button>
        <button id="slideForward" className="glide__arrow glide__arrow--right banner-btn-right" data-glide-dir=">"><span className="icon-carousel-arrow-right" /></button>
    </div>
  );
}

export default GenericSlideShowControls;
