import React from 'react';
import PropTypes from 'prop-types';
import Img from '../../../../productListComponent/ProductListComponentImage';

const genericSlideShowBanner = ({ thumborSizes, media, url, target }) => {

  const minimumWindowSize = 600;

  const mobileUrlTransformer = media => {
    const imageType = media.slice(-4);
    const mainImageUrl = media.replace(imageType, `-mobile${imageType}`);
    return mainImageUrl;
  }

  let imageHolder = (
    <Img
      sizes={['100vw', '90vw', '80vw', '70vw', '60vw', '50vw', '40vw', '30vw', '20vw', '10vw', '5vw']}
      thumborSizes={thumborSizes}
      alt=""
      imgSrc={window.innerWidth <= minimumWindowSize ? mobileUrlTransformer(media) : media}
      cName="lazyload rotating-banner-image"
      initialSize={window.innerWidth}
    />
  );

  if (url) {
    imageHolder = (
      <a href={url} target={target ? target : '_self'}>
        {imageHolder}
      </a>
    )
  }

  return (
    <li className="glide__slide slider__frame" >
      {imageHolder}
    </li>
  );
}

genericSlideShowBanner.protoTypes = {
  thumborSizes: PropTypes.any,
  media: PropTypes.string.isRequired,
  url: PropTypes.string,
}

export default genericSlideShowBanner;