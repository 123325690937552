import React from 'react';
import GenericSlideShowBanner from './genericSlideShowBanner';
import PropTypes from 'prop-types';

const genericSlideShowBannerHolder = ({ images }) => {
  const mainSize = {
    xl: 2560,
    ll: 1920,
    ml: 1704,
    l: 1280,
    llm: 1024,
    lm: 800,
    mm: 640,
    sm: 400,
    m: 320,
    s: 240,
    xs: 120
  }

  const thumborSizes = [];
  Object.entries(mainSize).forEach((value) => {
    thumborSizes.push(value[1]);
  });

  return (
    <ul className="glide__slides">
      {images.map((image, i) => {
        return (
          <GenericSlideShowBanner
            media={image.media}
            thumborSizes={thumborSizes}
            url={image.url ? image.url : null}
            key={i}
            target={image.target}
            mobile
          />
        )
      }
      )}
    </ul>
  );
}

genericSlideShowBannerHolder.protoTypes = {
  images: PropTypes.object,
}

export default genericSlideShowBannerHolder;
