import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Glide from '@glidejs/glide';
import GenericSlideShowControls from './genericSlideShowControls';
import GenericSlideShowBannerHolder from './bannerHolder/genericSlideShowBannerHolder';

class GenericSlideShowContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: props.images,
            dots: [],
        };
    }

    componentWillMount() {
        const dots = [];
        if (this.state.images.length > 1) {
            this.state.images.forEach((item, index) => {
                dots.push({
                    active: !(index),
                });
            });
        }
        this.setState({
            dots,
        });
    }

    componentDidMount() {
        let autoplay = (window.timeToSlide !== undefined) ? parseInt(window.timeToSlide) : 6000;
        const glide = new Glide('.glide', {
            type: 'carousel',
            focusAt: 0,
            perTouch: 1,
            perView: 1,
            touchRatio: 1,
            startAt: 0,
            perSwipe: '|',
            rewind: false,
            autoplay: 6000,
        });
        glide.mount();
    }

    render() {
        let displayedDots;
        if (this.state.dots.length > 1) {
            displayedDots = this.state.dots.map((dot, index) => (
                <button key={this.state.images[index].media} className="glide__bullet" data-glide-dir={`=${index}`}></button>
            )
            );
        };
        return (
            <div className="glide generic-slideshow generic-glide-style">
                <div className="glide__bullets" data-glide-el="controls[nav]">
                    {displayedDots}
                </div>
                <div data-glide-el="track" className="glide__track">
                    <GenericSlideShowBannerHolder images={this.state.images} />
                </div>
                <GenericSlideShowControls />
            </div>
        );
    }
}

GenericSlideShowContainer.propTypes = {
    images: PropTypes.any
};

export default GenericSlideShowContainer;