import React, { Component } from 'react';
import ThumborUtils from '../utils/thumborUtils';
import PropTypes from 'prop-types';

const { thumborConfig } = window.inlineGlobalConfig;
class Image extends Component {
  constructor(props) {
    super(props);
    this.renderSingleUrl = this.renderSingleUrl.bind(this);
    this.renderAllUrl = this.renderAllUrl.bind(this);
    this.renderAllSizes = this.renderAllSizes.bind(this);
  }

  renderSingleUrl(size) {
    const thumborUtils = new ThumborUtils(thumborConfig);
    return thumborUtils
      .setImagePath(encodeURIComponent(this.props.imgSrc))
      .fitIn(size)
      .buildUrl();
  }

  renderAllUrl() {
    let srcSet = '';
    this.props.thumborSizes.forEach((size) => {
      srcSet = `${srcSet} ${this.renderSingleUrl(size)} ${size}w,`;
    });
    return srcSet;
  }

  renderAllSizes() {
    let allSizes = '';
    this.props.sizes.forEach((size) => {
      allSizes = `${allSizes} ${size},`;
    });
    return allSizes;
  }

  render() {
    return (
      <picture onClick={this.props.onClick}>
        <img
          id={this.props.id || ''}
          alt=""
          className={this.props.cName}
          sizes={this.renderAllSizes()}
          data-src={this.renderSingleUrl(this.props.initialSize)}
          data-srcset={this.renderAllUrl()}
        />
      </picture>
    );
  }
}

Image.propTypes = {
  onClick: PropTypes.func,
  imgSrc: PropTypes.string,
  initialSize: PropTypes.number,
  thumborSizes: PropTypes.array,
  sizes: PropTypes.any,
  cName: PropTypes.string,
  id: PropTypes.string,
};

export default Image;
